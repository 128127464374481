//@ts-nocheck
import React, {Suspense} from "react";
import {Route, Switch} from "react-router-dom";

import Login from "./containers/Login/Login";
import Topics from "./containers/Topics/Topics";
import {Articles} from "./containers/Articles/Articles";
import TopicArticles from './containers/TopicArticles';
import Article from './containers/Article';
import StrapiLoginRedirection from "./containers/Strapi/StrapiLoginRedirection";
import Loading from "./containers/Loading/Loading";
import {ProtectedRoute} from "./contexts/ProtectedRoute";
import Search from './containers/Search'

const Router: React.FC = () => {
    return (
        <Suspense fallback={null}>
            <Switch>
                <Route exact path={'/'} component={Login} />
                <Route exact path={'/loading'} component={Loading} />
                <Route exact path={'/connect/auth0/redirect'} component={StrapiLoginRedirection} />

                <ProtectedRoute exact path={'/topics'} component={Topics} />
                <ProtectedRoute exact path={'/topics/:topicId/articles'} component={TopicArticles} />
                <ProtectedRoute exact path={'/articles/:articleId'} component={Article} />
                <ProtectedRoute path={'/search'} component={Search} />
            </Switch>
        </Suspense>
    );
};

export default Router
