import { createSlice } from '@reduxjs/toolkit'
import ThinkTank from '../models/ThinkTank';
import Topic from '../models/Topic';

export const coreSlice = createSlice({
  name: 'core',
  initialState: {
    topics: new Array<Topic>(),
    thinkTanks: new Array<ThinkTank>(),
  },
  reducers: {
    setTopics: (state, action) => {
      state.topics = action.payload;
    },
    setThinkTanks: (state, action) => {
      state.thinkTanks = action.payload;
    }
  }
})

// Action creators are generated for each case reducer function
export const { setTopics, setThinkTanks } = coreSlice.actions;

export default coreSlice.reducer;
