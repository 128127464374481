import React, { useContext, useEffect, useState } from "react";

import styled from "styled-components";
import ArticleBox from "../components/ArticleBox";
import { Palette } from "../models/Palette";
import { useDispatch, useSelector } from "react-redux";
import Images from "../assets/images/Images";
import { remove, empty as emptySearchTags } from "../reducers/searchTagsSlice";
import Loader from "react-loader-spinner";
import Article from "../models/Article";
import ArticlesService from "../services/ArticlesService";
import Tag from "../models/Tag";
import InfiniteScroll from "react-infinite-scroller";
import TagPill from "../components/TagPill";
import ArticlesGrid from "../components/ArticlesGrid";

const StyledSearch = styled.div`
  height: 100%;
  padding-top: 46px;
  padding-bottom: 46px;

  .search-box__wrapper {
    padding-top: 24px;
    padding-right: 24px;
    padding-left: 30px;
    height: 100%;
    border: 2px solid ${ Palette.secondary };
    display: flex;
    flex-direction: column;
  }

  .search-box__selected-tags {
    list-style: none;
    display: flex;
    flex-direction: row;
    padding-left: 0px;

    li {
      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }

  .search-box__section-separator {
    margin: 24px 0;
    border-bottom: 2px solid ${ Palette.secondary };
    width: 100%;
    content: '';
    display: block;
  }

  .search-box__spinner {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column: 1/-1;
    padding: 24px 0;
  }

  .search-box__results {
    overflow-y: scroll;
    padding-bottom: 24px;
  }

  @media screen and (max-width: 768px) {
    padding: 0;
    padding-top: 32px;

    .topic {
      margin: 0;
      margin-bottom: 24px;
    }

    .search-box__wrapper {
      border: none;
      padding: 0;
    }

    .search-box__selected-tags {

    }

    .search-box__section-separator {
      margin: 0;
      margin-bottom: 16px;
    }
  }
`;

const Search = () => {
  // @ts-ignore
  const searchTags = useSelector(state => state.searchTags.tags);
  const dispatch = useDispatch();
  const [articles, setArticles] = useState<Article[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const loadNextPage = async (currentPage: number) => {
    const { articles: newArticles, page, totalPages } =
      await ArticlesService.fetchAll({
        page: currentPage,
        tagNames: searchTags.map((tag: Tag) => tag.name)
      });

    setArticles([...articles, ...newArticles]);
    setHasMore(page < totalPages);
  };

  useEffect(() => {
    return () => {
      dispatch(emptySearchTags());
    }
  }, []);

  useEffect(() => {
    setArticles([]);
    setHasMore(true);
  }, [searchTags]);

  return (
    <StyledSearch className='search-box'>
      <div className='content-wrapper'>
        <div className='search-box__wrapper'>
          <div className='search-box__header'>
            <h3>Search results:</h3>
            <ul className='search-box__selected-tags'>
              { searchTags.map((tag: any) => (
                <li className='selected-tag' key={tag.id}>
                  <TagPill
                    label={ tag.name }
                    onClose={ () => dispatch(remove(tag.id)) }
                    theme='light'
                    showCloseButton={ true }
                  />
                </li>
              )) }
            </ul>
          </div>
          <span className='search-box__section-separator' />
          <div className='search-box__results'>
            <ArticlesGrid
              articles={ articles }
              loadNextPage={ loadNextPage }
              hasMore={ hasMore }
            />
          </div>
        </div>
      </div>
    </StyledSearch>
  )
};

export default Search;
