import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {Redirect} from "react-router-dom";
import Container from "../../components/Container";

import styled from "styled-components";
import Auth from "../../contexts/Auth";
import Images from "../../assets/images/Images";

// @media (min-width: 481px) {
// }
//
// @media (max-width: 480px) {
// }

const LoginContainer = styled.div `
    @media (min-width: 481px) {
        display: flex;
        align-items: center;
        flex-direction: row;
        flex: 1;
        justify-content: center;
    }

    @media (max-width: 480px) {
        display: flex;
        align-items: center;
        flex-direction: column;
        flex: 1;
        justify-content: center;
    }
`;

const AccessButton = styled.a`
    text-align: center;
    color: #000;
    background-color: #FFF;
    font-weight: 600;
    padding: 20px;

    &:hover {
       background-color: #3d4f5d;
    }

    @media (min-width: 481px) {
        width: 25vw;
    }

    @media (max-width: 480px) {
        width: 50vw;
    }
`;

const LeftContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 1;

    @media (min-width: 481px) {
        justify-content: center;

        .userAccess {
            font-size: 65px;
            margin-top: 20%;
            margin-bottom: 20%;
            text-align: center;
        }

        .userLinks {
            display: flex;
            flex-direction: row;
            width: 60%;
            justify-content: space-around;
            font-size: 30px;

            a {
              color: white;
              text-decoration: none;
            }
        }
    }

    @media (max-width: 480px) {
        justify-content: space-between;
        padding-top: 30px;

        .userAccess {
            font-size: 35px;
            margin-top: 20%;
            margin-bottom: 20%;
            text-align: center;
        }

        .userLinks {
            display: none;
        }
    }
`;

const RightContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 1;
    justify-content: flex-start;

    @media (min-width: 481px) {
    }

    @media (max-width: 480px) {
    }
`;


const TitleLogo = styled.img`
    @media (min-width: 481px) {
        height: 150px;
    }

    @media (max-width: 480px) {
        height: 100px;
    }
`;

const SeparatorDiv = styled.div`
    width: 2px;
    background-color: white;
`;

const Login: React.FC = () => {
    if (Auth.isAuthenticated()) {
        return <Redirect to={'/topics'} />
    }

    // cambiar svg -> Images
    // Poner tipografia

    return (
        <Container customStyles={{display: "flex"}}>
            <LoginContainer>
                <LeftContainer>
                    <div className={'userAccess'}>
                        <span>User</span>  <span style={{fontWeight: 600}}>Access</span>
                    </div>
                    <div className={'userLinks'}>
                        <div><a href="https://mail.after-math.es/webmail">Mail</a></div>
                        <SeparatorDiv />
                        <div><a href="https://mail.after-math.es/cpanel">Manage</a></div>
                        <SeparatorDiv />
                        <div><a href="https://admin.intelligence.after-math.es/admin">Editor</a></div>
                    </div>
                </LeftContainer>
                <RightContainer>
                    <AccessButton href={`${process.env.REACT_APP_API_URL}/connect/auth0`}>
                        Access
                    </AccessButton>
                </RightContainer>
            </LoginContainer>
        </Container>
    );
};

export default Login
