import React, {useContext, useEffect} from "react";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {setJwt} from "../../api";
import Loading from "../Loading/Loading";
import {AuthContext} from "../../contexts/AuthContext";
import Auth from "../../contexts/Auth";

const StrapiLoginRedirection: React.FC = () => {
    const location = useLocation();
    const history = useHistory();
    const backendUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        fetch(`${backendUrl}/auth/auth0/callback${location.search}`)
            .then(res => {
                if (res.status !== 200) {
                    throw new Error(`Couldn't login to Strapi. Status: ${res.status}`);
                }
                return res;
            })
            .then(res => res.json())
            .then(res => {
                Auth.login(res.jwt, res.user.username, () => history.push('/topics'))
            })
            .catch(err => {
                console.log(err);
            });
    }, [history, location.search]);

    return (
        <Loading />
    )
};

export default StrapiLoginRedirection
