import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Palette } from '../models/Palette';
import Article from '../models/Article';
import { Link } from 'react-router-dom';
import BREAKPOINTS from '../models/Breakpoints';

interface ArticleBoxProps {
  article: Article;
  className?: string;
}

const ArticleBox = ({ article, className }: ArticleBoxProps) => {
  return (
    <StyledArticleBox
        className={ classNames(className, 'article-box') }
        to={`/articles/${ article.id }`}
    >
      <div className='article-box__header'>
        <span className='article-box__topic-name'>{ article.topicName }</span>
        <time dateTime={ article.createdAt.toISOString() } className='article-box__publish-date'>{ article.createdAt.toDateString() }</time>
      </div>
      <h4 className='article-box__title'>
        { article.title }
      </h4>
      <p className='article-box__content'>
        { article.body }
      </p>
      <span className='article-box__read-more'>Read more...</span>
    </StyledArticleBox>
  )
};

const StyledArticleBox = styled(Link)`
  background-color: #04515B;
  padding: 24px;
  border: 1px solid ${ Palette.secondary };
  color: ${ Palette.secondary };
  text-decoration: none;
  height: 386px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;

  .article-box__header {
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    justify-content: space-between;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.625rem;
    font-weight: 300;

    text-transform: uppercase;
    letter-spacing: 0.09375rem;
  }

  .article-box__title {
    flex: 0 1 auto;
    font-family: 'Source Serif Pro', serif;
    font-size: 1.5rem;
    font-weight: 700;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
  }

  .article-box__content {
    flex: 1 1 auto;
    font-size: 0.875rem;
    font-weight: 300;
    font-family: 'Open Sans', sans-serif;
  }

  .article-box__read-more {
    flex: 0 1 auto;
    font-size: 0.875rem;
    font-weight: 700;
    font-family: 'Open Sans', sans-serif;
  }

  @media screen and (max-width: ${ BREAKPOINTS.tablet }px) {
    height: 250px;
    padding-top: 16px;

    .article-box__header {
      margin: 0;
      margin-bottom: 8px;
    }

    .article-box__publish-date {
      display: none;
    }

    .article-box__title {
      margin: 0;
      margin-bottom: 8px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      display: -webkit-box;
    }

    .article-box__content {
      margin: 0;
      margin-bottom: 16px;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      overflow: hidden;
      display: -webkit-box;
    }
  }
`;

export default ArticleBox;
