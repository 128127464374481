import Topic from "../models/Topic";
import ApiClient from '../api/index';
import Article from "../models/Article";

interface Service {
  fetchAll: () => Promise<Topic[]>;
  // fetch: (id: number) => Promise<Topic>;
}

interface ApiTopic {
  id: number;
  name: string;
  description: string;
  last_article_title: string;
  last_article_body: string;
}

const buildTopic = (apiTopic: ApiTopic): Topic => (
  {
    id: apiTopic.id,
    name: apiTopic.name,
    description: apiTopic.description,
    lastArticle: {
      title: apiTopic.last_article_title,
      body: apiTopic.last_article_body,
    }
  }
);

const TopicsService = ((): Service => {
  const fetchAll = (): Promise<Topic[]> => (
    ApiClient
      .get('/topics')
      .then((response: any) => {
        const data = response.data;

        return data.map(buildTopic);
      })
  );

  return {
    fetchAll,
  };
})();

export default TopicsService;
