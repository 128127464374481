import React from "react";
import { Link } from "react-router-dom";

import styled from 'styled-components';
import BREAKPOINTS from "../../models/Breakpoints";

import { Palette } from '../../models/Palette';
import Topic from "../../models/Topic";

const StyledTopicBox = styled(Link)`
  border: 2px solid ${ Palette.primary };
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;
  padding-bottom: 44px;
  position: relative;

  max-height: 309px;

  text-decoration: none;
  background-color: ${ Palette.tertiary };
  overflow-y: hidden;

  .topic-box__title {
    color: ${ Palette.on.primary };
    font-family: 'Open Sans', sans-serif;
    font-size: 1.125rem;
    font-weight: 700;
    margin: 0px;
    display: -webkit-box;
  }

  .topic-box__separator {
    display: inline-block;
    width: 65px;
    height: 2px;
    content: '';
    border-bottom: 2px solid ${ Palette.primary };
    margin-top: 8px;
    margin-bottom: 24px;
  }

  .topic-box__content {
    color: ${ Palette.on.primary };
    font-family: "Source Serif Pro", georgia, serif;
    font-size: 0.9375rem;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 9;

    overflow: hidden;
  }

  .topic-box__last-article {
    color: ${ Palette.secondary };

    h4 {
      margin: 0;
      font-size: 1.5rem;
      font-family: 'Source Serif Pro', serif;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      display: -webkit-box;
    }

    p {
      font-size: 0.875rem;
      font-family: 'Open Sans', sans-serif;
    }
  }

  .topic-box__description {
    display: none;
  }

  .topic-box__last-article {
    display: block;
  }

  .topic-box__shadow {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 76px;
    background: linear-gradient(180deg, rgba(4, 81, 91, 0) 0%, #053D46 100%);
  }

  @media screen and (max-width: ${ BREAKPOINTS.tablet }px) {
    height: 140px;
    padding: 16px 24px;

    .topic-box__separator {
      margin-top: 8px;
      margin-bottom: 8px;
    }

    .topic-box__description {
      display: block;
    }

    .topic-box__last-article {
      display: none;
    }
  }
`;

export interface TopicBoxProps {
  topic: Topic
}

export const TopicBox = ({ topic }: TopicBoxProps) => {
  return (
    <StyledTopicBox to={ `/topics/${topic.id}/articles` } className='topic-box'>
      <h3 className='topic-box__title'>{ topic.name }</h3>
      <span className='topic-box__separator' />
      <p className='topic-box__description'>{ topic.description }</p>
      <div className='topic-box__last-article'>
        { topic.lastArticle &&
            <>
              <h4>{ topic.lastArticle.title }</h4>
              <p>{ topic.lastArticle.body }</p>
            </>
        }
      </div>
      <div className='topic-box__shadow' />
    </StyledTopicBox>
  );
};
