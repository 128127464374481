import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import TagPill from "../components/TagPill";
import BREAKPOINTS from "../models/Breakpoints";
import { Palette } from "../models/Palette";
import Tag from "../models/Tag";
import { add } from "../reducers/searchTagsSlice";
import TagsService from "../services/TagsService";
import Images from "../assets/images/Images";


const StyledSuggestion = styled.li`
  &:not(:last-child) {
    margin-right: 8px;
  }
`;

const Suggestion = (props: any) => {
  return (
    <StyledSuggestion onClick={ props.onClick }>
      <TagPill
        label={ props.label.toLowerCase() }
        onClick={ props.onClick }
        theme='dark'
        showCloseButton={ false }
      />
    </StyledSuggestion>
  );
};

const StyledSearchBar = styled.div`
  width: 100%;
  border-bottom: 2px solid ${ Palette.secondary };
  position: relative;

  input {
    width: 100%;
    height: 32px;
    padding: 5px 24px;
    border: none;
    background-image: none;
    background-color: transparent;
    box-shadow: none;
    font-size: 0.875rem;
    font-family: 'Open Sans', sans-serif;
    color: ${ Palette.secondary };

    transition: background-color .3s ease-in;
  }

  input:focus {
    background-color: rgba(0, 0, 0, .1);
  }

  .search-bar__text-block {
    position: absolute;
    opacity: 0;
    z-index: -1;
    font-size: 0.875rem;
    font-family: 'Open Sans', sans-serif;
  }

  .search-bar__glass {
    position: absolute;
    top: 7px;
    right: 7px;
    pointer-events: none;
  }

  .search-bar__suggestions {
    position: absolute;
    top: 5px;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    list-style: none;
  }

  @media screen and (max-width: ${ BREAKPOINTS.tablet }px) {
    border-bottom: none;
    height: 32px;
  }
`;

const SearchBar = (props: any) => {
  const history = useHistory();
  const location = useLocation();
  const [searchText, setSearchText] = useState<string>('');
  const [searchTextWidth, setSearchTextWidth] = useState<number>(0);
  const [suggestions, setSuggestions] = useState<Tag[]>([]);
  const textBlockRef = useRef(null);
  const searchBoxRef = useRef(null);
  const dispatch = useDispatch();
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const { onEmptySearch, onSearch } = props;

  const onSearchInput = (e: any) => {
    setSearchText(e.target.value);
  };

  const onBlur = (e: any) => {
    if (!searchText) {
      history.goBack();
      setIsFocused(false);
      onEmptySearch && onEmptySearch();
    }
  };

  const onFocus = (e: any) => {
    history.push(`/search`);
    setIsFocused(true);
    onSearch && onSearch();
  };

  useEffect(() => {
    if (textBlockRef !== null) {
      const current: any = textBlockRef.current;
      setSearchTextWidth(current.clientWidth);
    }

    if (searchText.length >= 3) {
      TagsService
        .fetchAll(searchText)
        .then((tags: Tag[]) => {
          setSuggestions(tags);
        });
    }
  }, [searchText])

  useEffect(() => {
    setSearchText('');
    setSuggestions([]);

    const current: any = searchBoxRef.current;
    current.value = '';
  }, [location])

  const onSuggestionClick = (suggestion: Tag) => (
    () => {
      dispatch(add(suggestion));
      setSuggestions(suggestions.filter(s => s.id !== suggestion.id));
    }
  );

  return (
    <StyledSearchBar className='search-bar'>
      <pre className='search-bar__text-block' ref={textBlockRef}>
        { searchText }
      </pre>
      <img src={ Images.search } className='search-bar__glass' />
      <input
        type='text'
        onChange={ onSearchInput }
        onBlur={ onBlur }
        onFocus={ onFocus }
        ref={ searchBoxRef }
      />
      <ul className='search-bar__suggestions' style={ { left: `${ 24 + 16 + searchTextWidth }px` } }>
        { suggestions.map((suggestion, i) => (
            <Suggestion
              label={ suggestion.name }
              key={ suggestion.id }
              onClick={ onSuggestionClick(suggestion) }
            />
        ))}
      </ul>
    </StyledSearchBar>
  );
};

export default SearchBar;
