export const Palette = {
  primary: '#F5F5F5',
  secondary: '#FFFFFF',
  tertiary: '#04515B',
  on: {
    primary: '#F5F5F5',
    secondary: '#2F585E'
  },
  background: '#2F585E'
};
