import Tag from "../models/Tag";
import ApiClient from '../api/index';

interface ApiTag {
  id: number;
  name: string;
}

interface Service {
  fetchAll: (name: string) => Promise<Tag[]>;
}

const buildTag = (apiTag: ApiTag): Tag => (
  {
    id: apiTag.id,
    name: apiTag.name
  }
);

const TagsService = ((): Service => {
  const fetchAll = (name: string): Promise<Tag[]> => (
    ApiClient
      .get(`/tags?name=${name}`)
      .then((response: any) => {
        const data = response.data;

        return data.map(buildTag);
      })
  );

  return {
    fetchAll
  };
})();

export default TagsService;
