import classNames from 'classnames';
import styled from 'styled-components';
import Images from '../assets/images/Images';

interface Props {
  label: string,
  onClick?: () => void,
  onClose?: () => void,
  theme: string,
  className?: string,
  showCloseButton?: boolean
}

const TagPill = ({ label, onClick, onClose, theme, className,
                   showCloseButton }: Props) => {
  return (
    <span
        className={
          classNames(
            'sans-serif',
            { 'dark': theme === 'dark' },
            { 'light': theme === 'light' },
            !!onClose ? 'close' : 'add',
            className
          )
        }
        onClick={ onClick }
    >
      <span className='label'>{ label.toLowerCase() }</span>
      { showCloseButton &&
        <button className='action' onClick={ onClose }>
          <img
            className={
              classNames(
                'action-btn',
                { 'action-btn--close': !!onClose },
                { 'action-btn--add': !onClose }
              )
            }
            src={ Images.cross_dark }
          />
        </button>
      }
    </span>
  );
};

const StyledTagPill = styled(TagPill)`
  display: flex;
  flex-direction: row;

  border-radius: 24px;
  padding: 2px 8px 2px 8px;

  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1rem;

  &.dark {
    background-color: rgba(255, 255, 255, 0.25);
    color: rgba(255, 255, 255, 0.75);
  }

  &.light {
    background-color: #FFFFFF;
    color: #04515B;
  }

  &.add {
    .action-btn {
      transform: rotate(45deg);
    }
  }

  &.close {
    .action-btn {
      transform: rotate(0deg);
    }
  }
`;

export default StyledTagPill;
