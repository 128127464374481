import React, { useState } from 'react';

import styled from 'styled-components';

import { TopicBox } from './TopicBox';

import Topic from '../../models/Topic';
import BREAKPOINTS from '../../models/Breakpoints';

interface ScrollProps {
  top: number;
  bottom: number;
};

const offsetToOpacity = (offset: number) => {
  const x = offset / 300;

  return x > 1 ? 1 : x;
};

const Scrollable = styled.div<ScrollProps>`
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    background-color: transparent;
    width: 0px;
  }

  &:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 78px;
    bottom: 0;
    right: 0;
    left: 0;
    pointer-events: none;
    background: linear-gradient(0deg, rgb(5, 61, 70, ${ p => offsetToOpacity(p.bottom) }) 0%, rgba(4,81,91,0) 100%);
  }

  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 78px;
    top: 0;
    right: 0;
    left: 0;
    pointer-events: none;
    background: linear-gradient(180deg, rgba(5, 61, 70, ${ p => offsetToOpacity(p.top) }) 0%, rgba(4,81,91,0) 100%);
  }
`;

const StyledTopicsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(272px, 1fr));
  grid-gap: 0.5rem;

  @media screen and (max-width: ${ BREAKPOINTS.tablet }px) {
    grid-template-columns: 1fr;
    grid-gap: 0.5rem;
  }
`;

export interface TopicsGridProps {
  topics: Topic[],
}

export const TopicsGrid = ({ topics }: TopicsGridProps) => {
  const [scrollTop, setScrollTop] = useState<number>(0);
  const [scrollBottom, setScrollBottom] = useState<number>(300);

  const onScroll = (e: any) => {
    const target = e.target;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    setScrollTop(scrollTop);
    setScrollBottom(scrollHeight - clientHeight - scrollTop);
  };

  return (
    <Scrollable onScroll={ onScroll } top={ scrollTop } bottom={ scrollBottom }>
      <StyledTopicsGrid className='topics-grid'>
        { topics.map(topic => {
            return <TopicBox key={ topic.name } topic={ topic } />
          })
        }
      </StyledTopicsGrid>
    </Scrollable>
  );
};
