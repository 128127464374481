import {clearJwt, setJwt} from "../api";
import jwt_decode from "jwt-decode";

class Auth {
    constructor() {
        this.jwt = localStorage.getItem('jwt');
        this.expiration = this.jwt ? jwt_decode(this.jwt).exp : 0;

        if (this.jwt && Math.floor(new Date().getTime()/1000) < this.expiration){
            this.authenticated = true;
            setJwt(this.jwt);
        } else {
            this.authenticated = false;
            clearJwt();
        }
    }

    login(callback) {
        this.authenticated = true;
        callback();
    }

    logout(callback) {
        this.authenticated = false;
        this.jwt = null;
        localStorage.removeItem('jwt')
        localStorage.removeItem('username')
        clearJwt();
        callback();
    }

    isAuthenticated() {
        return this.authenticated;
    }

    login(jwt, username, callback) {
        this.authenticated = true;
        localStorage.setItem('jwt', jwt);
        localStorage.setItem('username', username);
        setJwt(jwt);

        callback();
    }
}

export default new Auth();
