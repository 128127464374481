import React from "react";
import InfiniteScroll from "react-infinite-scroller";
import Loader from "react-loader-spinner";
import styled from "styled-components";
import Article from "../models/Article";
import ArticleBox from "./ArticleBox";

interface Props {
  articles: Article[];
  loadNextPage: (i: number) => void;
  hasMore: boolean;
  className?: string;
}

const ArticlesGrid = ({ articles, loadNextPage, hasMore, className }: Props) => {
  return (
    <InfiniteScroll
        className={className}
        useWindow={false}
        threshold={100}
        loadMore={loadNextPage}
        pageStart={-1}
        hasMore={hasMore}
        loader={
          <div className='search-box__spinner'>
            <Loader type="TailSpin" color="#ffffff" height={64} width={64} />
          </div>
        }
    >
      { articles.map(article => <ArticleBox key={article.id} article={ article } />)}
    </InfiniteScroll>
  )
};

const StyledArticlesGrid = styled(ArticlesGrid)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(343px, 1fr));
  grid-gap: 0.5rem;
`;

export default StyledArticlesGrid;
