import React, {useContext, useEffect, useState} from "react";

import styled from "styled-components";

import {TopicsGrid} from "../../components/Common/TopicsGrid";
import {PageTitle} from "../../components/PageTitle/PageTitle";
import BREAKPOINTS from "../../models/Breakpoints";
import { useSelector } from "react-redux";

const StyledTopics = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 46px;
  padding-bottom: 66px;

  overflow-y: hidden;

  .topics__container {
    overflow-y: hidden;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
  }

  .topics__page-title {
    flex: 0 1 auto;
    padding-right: 48px;
  }

  .topics__topics-list {
    flex: 1 1 68%;
    position: relative;

    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .topics__think-tanks {
    flex: 0 1 32%;
    overflow-y: scroll;
  }

  @media screen and (max-width: ${BREAKPOINTS.tablet}px) {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 0;

    .topics__container {
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
    }

    .topics__page-title {
      display: none;
    }

    .topics__topics-list {
      flex: 0 1 100%;
    }

    .topics__think-tanks {
      display: none;
    }
  }
`;

const Topics: React.FC = (): JSX.Element => {
    // @ts-ignore
    const topics = useSelector(state => state.core.topics);

    return (
      <StyledTopics className='topics'>
        <div className='content-wrapper'>
          <div className='topics__container'>
            <div className='topics__page-title'>
              <PageTitle title={'Intelligence'} subtitle={'Geopolitics'}/>
            </div>
            <div className='topics__topics-list'>
              <TopicsGrid topics={topics}/>
            </div>
          </div>
        </div>
      </StyledTopics>
    );
};

export default Topics
