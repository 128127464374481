import Article from "../models/Article";
import ApiClient from '../api/index';

interface ApiArticle {
  id: number;
  title: string;
  published_at: string;
  created_by: string;
  body: string;
  topic_name?: string;
}

interface FetchAllParameters {
  page?: number;
  pageSize?: number;
  topicId?: number;
  tagNames?: string[];
}

interface FetchAllResponse {
  page: number,
  total_pages: number,
  articles: ApiArticle[]
}

interface FetchAllResult {
  page: number;
  totalPages: number;
  articles: Article[];
}

interface Service {
  fetch: (id: number) => Promise<Article>;
  fetchAll: (params: FetchAllParameters) => Promise<FetchAllResult>;
}

const buildArticle = (apiArticle: ApiArticle): Article => (
  {
    id: apiArticle.id,
    title: apiArticle.title,
    author: apiArticle.created_by,
    createdAt: new Date(apiArticle.published_at),
    body: apiArticle.body,
    topicName: apiArticle.topic_name,
  }
);

const ArticlesService = ((): Service => {
  const fetch = (id: number): Promise<Article> => {
    const url = `/articles/${id}`;

    return ApiClient
      .get(url)
      .then((response: any): Article => {
        const articleJson = response.data;
        const article =  {
          id: articleJson.id,
          title: articleJson.title,
          author: articleJson.author,
          createdAt: new Date(articleJson.created_at),
          body: articleJson.body,
          topicId: articleJson.topic.id
        };

        return article;
      });
  }

  const fetchAll = async (params: FetchAllParameters): Promise<FetchAllResult> => {
    const page = params.page || 0;
    const pageSize = params.pageSize || 12;

    let url = `/articles?page=${page}&page_size=${pageSize}`;

    if (params.topicId) {
      url = `${url}&topic_id=${params.topicId || ''}`;
    }

    if (params.tagNames) {
      url = `${url}&tags=${params.tagNames.join(',') || ''}`;
    }

    return await ApiClient
      .get(url)
      .then((response: any): FetchAllResult => {
        const data: FetchAllResponse = response.data;

        return {
          articles: data.articles.map(buildArticle),
          page: data.page,
          totalPages: data.total_pages,
        }
      });
  };

  return {
    fetch,
    fetchAll
  };
})();

export default ArticlesService;
