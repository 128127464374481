import ThinkTank from "../models/ThinkTank";

interface Service {
  fetchAll: () => Promise<ThinkTank[]>;
}

const ThinkTanksService = ((): Service => {
  const fetchAll = (): Promise<ThinkTank[]> => {
    return Promise.resolve([
        {name: 'Adam Smith Institute', link: 'https://www.adamsmith.org/'},
        {name: 'Atlantic Council', link: 'https://www.atlanticcouncil.org/'},
        {name: 'BBVA Research', link: 'https://www.bbvaresearch.com/en/'},
        {name: 'Belfer Center for Science and International Affairs', link: 'https://www.belfercenter.org/'},
        {name: 'Brookings', link: 'https://www.brookings.edu/'},
        {name: 'Bruegel', link: 'https://www.bruegel.org/'},
        {name: 'Brunswick', link: 'https://www.brunswickgroup.com/home/'},
        {name: 'Carnegie Endowment for International Peace', link: 'https://carnegieendowment.org/'},
        {name: 'Center for Strategic and International Studies (CSIS)', link: 'https://www.csis.org/'},
        {name: 'Centre for Economic Policy Research (CEPR)', link: 'https://cepr.org/'},
        {name: 'Centre for European Policy Studies (CEPS)', link: 'https://www.ceps.eu/'},
        {name: 'Chatham House', link: 'https://www.chathamhouse.org/'},
        {name: 'Council on Foreign Relations', link: 'https://www.cfr.org/'},
        {name: 'Economic Policy Institute', link: 'https://www.epi.org/'},
        {name: 'Egmont Institute - Royal Institute for International Relations', link: 'https://www.egmontinstitute.be/'},
        {name: 'Elcano Royal Institute', link: 'http://www.realinstitutoelcano.org/wps/portal/rielcano_en'},
        {name: 'EuroIntelligence', link: 'https://www.eurointelligence.com/'},
        {name: 'European Council on Foreign Relations', link: 'https://www.ecfr.eu/'},
        {name: 'European Union Institute for Security Studies', link: 'https://www.iss.europa.eu/'},
        {name: 'Foreign Affairs', link: 'https://www.foreignaffairs.com/'},
        {name: 'Foreign Policy', link: 'https://foreignpolicy.com/'},
        {name: 'French Institute of International Relations', link: 'https://www.ifri.org/en'},
        {name: 'German Institute for International Politics and Security', link: 'https://www.swp-berlin.org/en/'},
        {name: 'Institute for the Study of War', link: 'http://www.understandingwar.org/'},
        {name: 'Institute of International Affairs / Istituto per Affari Internazionali (IAI)', link: 'https://www.iai.it/en'},
        {name: 'Institute of International Finance', link: 'https://www.iif.com/'},
        {name: 'International Crisis Group', link: 'https://www.crisisgroup.org/'},
        {name: 'International Institute for Strategic Studies', link: 'https://www.iiss.org/'},
        {name: 'IPPR', link: 'https://www.ippr.org/'},
        {name: 'Mercartor Institute for China Studies (MERICS)', link: 'https://merics.org/en'},
        {name: 'Mises Institute', link: 'https://mises.org/'},
        {name: 'Munich Security Conference', link: 'https://securityconference.org/'},
        {name: 'National Bureau of Economic Research', link: 'https://www.nber.org/'},
        {name: 'Nueva Economía Fórum', link: 'http://www.nuevaeconomiaforum.org/'},
        {name: 'Oxford Analytica', link: 'https://dailybrief.oxan.com/'},
        {name: 'Oxford Economics', link: 'https://www.oxfordeconomics.com/'},
        {name: 'RAND', link: 'https://www.rand.org/'},
        {name: 'Stratfor', link: 'https://worldview.stratfor.com/'},
        {name: 'VoxEU', link: 'https://voxeu.org/'},
        {name: 'Cato institute', link: 'https://www.cato.org/'},
        {name: 'Institute of War', link: 'http://www.understandingwar.org/'},
        {name: 'iif ', link: 'https://www.iif.com/'},
        {name: 'globalsource', link: 'https://www.globalsourcepartners.com/'},
        {name: 'merics', link: 'https://merics.org/en'},
        {name: 'Real Instituto ElCano', link: 'http://www.realinstitutoelcano.org/'},
        {name: 'Chatam house', link: 'https://www.chathamhouse.org/'},
        {name: 'Foreign Policy ', link: 'https://foreignpolicy.com/'}
    ]);
  };

  return {
    fetchAll: fetchAll
  }
})();

export default ThinkTanksService;
