import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Auth from "./Auth";
import TopicsService from '../services/TopicsService';
import ThinkTanksService from '../services/ThinkTanksService';
import { setTopics, setThinkTanks } from '../reducers/coreSlice';

const PreloadWrapper = ({ children }: any) => {
  // @ts-ignore
  const topics = useSelector(state => state.core.topics);
  // @ts-ignore
  const thinkTanks = useSelector(state => state.core.thinkTanks);
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const promises = [];

    if (!topics || topics.length === 0) {
      promises.push(
        TopicsService
          .fetchAll()
          .then((fetchedTopics) => dispatch(setTopics(fetchedTopics)))
      )
    }

    if (!thinkTanks || thinkTanks.length === 0) {
      promises.push(
        ThinkTanksService
          .fetchAll()
          .then((fetchedThinkTanks) => dispatch(setThinkTanks(fetchedThinkTanks)))
      )
    }

    Promise.all(promises).then(() => setIsLoaded(true));

    return;
  }, []);

  return (
    <>
      { !isLoaded && <div>Loading</div> }
      { isLoaded && children }
    </>
  )
};

export const ProtectedRoute = ({ component: Component, ...rest}: any) => {
    return (
        <Route
          {...rest}
          render={ ({location}) => {
            if (Auth.isAuthenticated()) {
              return (
                <PreloadWrapper>
                  <Component />
                </PreloadWrapper>
              );
            } else {
              return <Redirect to={'/'} />
            }
          }}
        />
    );
};
