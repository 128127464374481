import { configureStore } from '@reduxjs/toolkit';

import searchTagsReducer from './reducers/searchTagsSlice';
import coreReducer from './reducers/coreSlice';

export default configureStore({
  reducer: {
    searchTags: searchTagsReducer,
    core: coreReducer,
  }
});
