import React, { useContext, useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import ReactMarkdown from "react-markdown";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from 'styled-components';

import ArticleModel from '../models/Article';
import BREAKPOINTS from "../models/Breakpoints";
import { Palette } from "../models/Palette";
import Topic from "../models/Topic";
import ArticlesService from "../services/ArticlesService";

const StyledArticle = styled.div`
  height: 100%;
  padding-top: 46px;
  padding-bottom: 46px;

  .article__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    border: 2px solid ${ Palette.secondary };
    padding: 24px;
  }

  .topic {
    flex: 1 0 auto;
    display: flex;
    flex-direction: row;
    width: 100%;
    min-width: 72px;
  }

  .topic__name {
    font-size: 1.5rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    flex: 1 0 auto;
    min-width: 166px;
    padding-right: 24px;
  }

  .topic__description {
    font-size: 0.875rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    margin: 0;
    flex: 1 1 auto;
  }

  .separator {
    display: block;
    content: '';
    width: 100%;
    border-bottom: 2px solid ${ Palette.secondary };
    margin-top: 24px;
    margin-bottom: 40px;
  }

  .article {
    flex: 1 1 auto;
    overflow-y: scroll;
    width: 75%;
    max-width: 834px;
  }

  .article__title {
    font-size: 2.25rem;
    font-weight: 700;
    font-family: 'Source Serif Pro', serif;
    margin: 0;
    margin-bottom: 1rem;
    line-height: 2.8125rem;
  }

  .article__author {
    font-size: 0.75rem;
    font-weight: 300;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 1.625rem;
  }

  .article__date {
    font-size: 0.75rem;
    font-weight: 700;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 3rem;
  }

  .article__body {
    font-size: 0.75rem;
    font-weight: 300;
    font-family: 'Open Sans', sans-serif;

    img {
      max-width: 100%;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

  @media screen and (max-width: ${ BREAKPOINTS.tablet }px) {
    .article {
      width: 100%;
    }
  }

`;

const Article = () => {
  // @ts-ignore
  const topics = useSelector(state => state.core.topics);
  const { articleId } = useParams<{ articleId: string }>();

  const [article, setArticle] = useState<ArticleModel | null>(null);
  const [topic, setTopic] = useState<Topic | null>(null);

  useEffect(() => {
    ArticlesService
      .fetch(parseInt(articleId))
      .then((article: ArticleModel) => {
        setArticle(article);

        const topic = topics.find((topic: Topic) => topic.id === article.topicId);
        setTopic(topic);
      });
  }, [])

  return (
    <StyledArticle>
      <div className='content-wrapper'>
        <div className='article__wrapper'>
          { (article && topic) &&
              <>
                <div className='topic'>
                  <span className='topic__name'>{ topic.name }</span>
                  <p className='topic__description'>{ topic.description }</p>
                </div>
                <span className='separator' />
                <div className='article'>
                  <h4 className='article__title'>{ article.title }</h4>
                  <div className='article__author'>By { article.author }</div>
                  <div className='article__date'>{ article.createdAt.toDateString() }</div>
                  <div className='article__body'>
                    <ReactMarkdown>{ article.body }</ReactMarkdown>
                  </div>
                </div>
            </> ||
            <span className='spinner'>
              <Loader type="Oval" color="#FFFFFF" height={100} width={100}/>
            </span>
          }
        </div>
      </div>
    </StyledArticle>
  )
};

export default Article;
