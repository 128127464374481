import { createSlice } from '@reduxjs/toolkit'

interface Tag {
  id: number;
  name: string;
}

export const searchTagsSlice = createSlice({
  name: 'searchTags',
  initialState: {
    tags: new Array<Tag>()
  },
  reducers: {
    add: (state, action) => {
      const newTag: Tag = action.payload;

      if (state.tags.find(tag => tag.id === newTag.id))
        return;

      const tags = [...state.tags, newTag];

      state.tags = tags;
    },
    remove: (state, action) => {
      state.tags = state.tags.filter(tag => tag.id !== action.payload);
    },
    empty: (state) => {
      state.tags = [];
    }
  }
})

// Action creators are generated for each case reducer function
export const { add, remove, empty } = searchTagsSlice.actions

export default searchTagsSlice.reducer
