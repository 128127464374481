import React from "react";
import styled from "styled-components";

import Images from '../../assets/images/Images';
import { Link } from "react-router-dom";
import BREAKPOINTS from "../../models/Breakpoints";
import SearchBar from "../../containers/SearchBar";

const StyledBreadcrumbs = styled.ul`
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 0;
  padding: 0;
  padding-top: 6px;

  li {
    font-size: 0.875rem;
    font-family: 'Open Sans', sans-serif;

    &:after {
      content: '/';
      margin: 0 16px;
    }
  }

  li:last-child {
    font-weight: 700;
  }
`;

const Breadcrumbs = () => {
  return (
    <StyledBreadcrumbs>
      <li>Intelligence</li>
      <li>Geopolitics</li>
    </StyledBreadcrumbs>
  );
};

const StyledHeader = styled.header`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 140px;
  padding-top: 80px;

  a {
    display: flex;
  }

  .header-wrapper {
    display: flex;
  }

  .header__logo {
    width: 182px;
    margin-right: 40px;
  }

  .header__search-bar {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
  }

  @media screen and (max-width: ${ BREAKPOINTS.tablet }px) {
    border-bottom: 2px solid #FFFFFF;
    padding: 24px;

    &.header {
      align-items: flex-end;
    }

    .header-wrapper.content-wrapper {
      height: fit-content;
    }

    .header__search-bar {
      display: none;
    }
  }
`;

const Header = () => {
    return (
      <StyledHeader className='header'>
        <div className='content-wrapper header-wrapper'>
          <Link to='/topics'>
            <img src={ Images.logo } className='header__logo' />
          </Link>
          <div className='header__search-bar'>
            <SearchBar />
            <Breadcrumbs />
          </div>
        </div>
      </StyledHeader>
    )
}

export default Header;
