import React from "react";
import Container from "../../components/Container";
import Loader from "react-loader-spinner";

const Loading: React.FC = () => {
    return (
        <Container customStyles={{display: "flex", alignItems: "center", justifyContent: 'center', flexDirection: 'column'}}>
            <div style={{marginBottom: 20}}> Loading </div>
            <div style={{marginBottom: 40}}> Please wait </div>
            <Loader type="Oval" color="#FFFFFF" height={100} width={100}/>
        </Container>
    );
};

export default Loading
