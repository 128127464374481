import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import Loader from "react-loader-spinner";

import { Palette } from "../models/Palette";
import Article from "../models/Article";
import { useParams } from "react-router-dom";
import Topic from "../models/Topic";
import ArticlesService from "../services/ArticlesService";
import ArticlesGrid from "../components/ArticlesGrid";

const StyledSearch = styled.div`
  height: 100%;
  padding-top: 46px;
  padding-bottom: 46px;

  .search-box__wrapper {
    padding-top: 24px;
    padding-right: 24px;
    padding-left: 30px;
    height: 100%;
    border: 2px solid ${ Palette.secondary };
    display: flex;
    flex-direction: column;
  }

  .topic {
    flex: 1 0 auto;
    display: flex;
    flex-direction: row;
    width: 100%;
    min-width: 72px;
  }

  .topic__name {
    font-size: 1.5rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    flex: 1 0 auto;
    min-width: 166px;
    padding-right: 24px;
  }

  .topic__description {
    font-size: 0.875rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    margin: 0;
    flex: 1 1 auto;
  }

  .search-box__selected-tags {
    list-style: none;
    display: flex;
    flex-direction: row;

    li {
      background-color: ${ Palette.secondary };
      color: ${ Palette.on.secondary };
      padding: 2px 8px;
      border-radius: 24px;
      font-family: 'Open Sans', sans-serif;
      font-size: 0.875rem;
      font-weight: 700;

      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }

  .search-box__section-separator {
    display: block;
    content: '';
    width: 100%;
    border-bottom: 2px solid ${ Palette.secondary };
    margin-top: 24px;
    margin-bottom: 40px;
  }

  .search-box__spinner {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .search-box__results {
    overflow-y: scroll;
    padding-bottom: 24px;
  }

  .search-box__results-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(343px, 1fr));
    grid-gap: 0.5rem;
  }

  .selected-tag__close-btn {
    padding-left: 13px;
    display: inline-block;
    color: ${ Palette.on.secondary };
    font-size: 0.875rem;
  }

  @media screen and (max-width: 768px) {
    padding: 0;
    padding-top: 32px;

    .topic {
      margin: 0;
      margin-bottom: 24px;
    }

    .search-box__wrapper {
      border: none;
      padding: 0;
    }

    .search-box__section-separator {
      margin: 0;
      margin-bottom: 16px;
    }
  }
`;

const Search = () => {
  // @ts-ignore
  const topics = useSelector(state => state.core.topics);
  const [articles, setArticles] = useState<Article[]>([]);
  const { topicId } = useParams<{ topicId: string }>();
  const [hasMore, setHasMore] = useState<boolean>(true);
  const topic = topics.find((topic: Topic) => topic.id === parseInt(topicId));

  const loadNextPage = async (currentPage: number) => {
    const { articles: newArticles, page, totalPages } = await ArticlesService.fetchAll({ page: currentPage, topicId: parseInt(topicId) });

    setArticles([...articles, ...newArticles]);
    setHasMore(page < totalPages);
  };

  return (
    <StyledSearch className='search-box'>
      <div className='content-wrapper'>
        <div className='search-box__wrapper'>
          <div className='topic'>
            <span className='topic__name'>{ topic.name }</span>
            <p className='topic__description'>{ topic.description }</p>
          </div>
          <span className='search-box__section-separator' />
          <div className='search-box__results'>
            <ArticlesGrid
              articles={ articles }
              loadNextPage={ loadNextPage }
              hasMore={ hasMore }
            />
          </div>
        </div>
      </div>
    </StyledSearch>
  )
};

export default Search;
