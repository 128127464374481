import React, { useState } from "react";
import {BrowserRouter} from "react-router-dom";
import Router from "./Router";
import {AuthContextProvider} from "./contexts/AuthContext";

import Images from "./assets/images/Images";
import styled from 'styled-components';

import Header from './components/Desktop/Header';
import { Provider } from "react-redux";
import store from "./store";
import SearchBar from "./containers/SearchBar";
import BREAKPOINTS from "./models/Breakpoints";
import PageTitle from "./containers/PageTitle";

const StyledAppContainer = styled.div`
  color: white;
  background-image: url(${Images.background});
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: 'Open Sans', sans-serif;

  display: flex;
  flex-direction: column;

  .header-wrapper {
    flex: 0 1 auto;
  }

  main {
    flex: 1 0 auto;
    height: calc(100% - 140px);
  }

  .section-title {
    display: none;
  }

  @media screen and (max-width: ${ BREAKPOINTS.tablet }px) {
    .section-title {
      display: block;
    }

    main {
      display: flex;
      flex-direction: column;
    }
  }
`;

const AppContainer = () => {
  const [openSearch, setOpenSearch] = useState<boolean>(false);

  return (
    <StyledAppContainer>
      <div className='header-wrapper'>
        <Header />
      </div>
      <main>
        <div className='section-title'>
          <div className='section-title__back-btn' />
          <SearchBar
            onEmptySearch={ () => setOpenSearch(false) }
            onSearch={() => setOpenSearch(true)}
          />
        </div>
        <AuthContextProvider>
          <Router />
        </AuthContextProvider>
      </main>
    </StyledAppContainer>
  )
}

const App: React.FC = () => {
    return (
      <BrowserRouter basename={process.env.REACT_APP_BASENAME || ''}>
        <Provider store={ store }>
          <AppContainer />
        </Provider>
      </BrowserRouter>
    );
};

export default App
