const Images = {
    background: require('./background.jpg').default,
    background2: require('./background.jpg').default,
    logo: require('./logo-aftermath_research.svg').default,
    cross: require('./cross.svg').default,
    cross_light: require('./cross-light.svg').default,
    cross_dark: require('./cross-dark.svg').default,
    search: require('./search.svg').default,
    arrow_right_up: require('./arrow-right_up.svg').default,
};

export default Images;
