import React from "react";
import styled from "styled-components";
import Images from "../assets/images/Images";

const ImageContainer = styled.div `
    color: white;
`;

interface ContainerInterface {
    children: React.ReactNode,
    customStyles?: object
}

const Container = ({ children, customStyles}: ContainerInterface) => {
    return (
        <ImageContainer id='Container' style={{...customStyles}}>
            { children }
        </ImageContainer>
    )
}

export default Container;
